<template>
  <div>
    <div class="success d-flex flex-column justify-content-center">
      <b-card text-variant="center" class="card">
        <b-avatar variant="primary" size="70" class="shadow mb-2">
          <feather-icon size="28" icon="CheckIcon" />
        </b-avatar>
        <h1 class="mb-1 mt-50 text-white">&#127881; Satınalımınız için teşekkür ederiz. &#127881;</h1>
        <b-card-text class="m-auto w-75"> Teşekkür ederiz! Satın alma işlemi başarılı bir şekilde tamamlandı 
          ve artık daha fazla danışan ekleyebilir, uygulama yapabilirsiniz. 
          Diko'yu tercih ettiğiniz için ayrıca teşekkür ederiz! Eğer herhangi bir sorunuz veya ihtiyacınız olursa, <b-link :to="{name:'diko-faq'}">Bilgi Bankasını</b-link> yada <b-link :to="{name:'guide'}">Kullanım Klavuzu</b-link> sayfalarını ziyaret edebilir veya
          bizimle iletişime geçebilirsiniz. 
          Başarılarınızı paylaşmayı dört gözle bekliyoruz!</b-card-text>
      </b-card>
    </div>
    <!-- <div class="deny d-flex flex-column justify-content-center" v-if="">
      <b-card text-variant="center" class="card bg-dark">
        <b-avatar variant="danger" size="70" class="shadow mb-2">
          <feather-icon size="28" icon="XIcon" />
        </b-avatar>
        <h1 class="mb-1 mt-50 text-white">Payment Denied</h1>
        <b-card-text class="m-auto w-75">
          We regret to inform you that your payment was not successful. Please double-check your payment information and ensure that there are
          sufficient funds available in your account. If you continue to experience difficulties, please reach out to your bank or payment provider
          for further assistance. Thank you for your understanding and please let us know if there is anything we can do to assist you.
        </b-card-text>
      </b-card>
    </div> -->
  </div>
</template>

<script>
import { BCard, BImg, BAvatar, BCardText,BLink } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BAvatar,
    BImg,
    BCardText,
    BLink
  },
};
</script>
<style scoped>
.success,
.deny {
  height: 80vh;
}
</style>
